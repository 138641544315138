import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')
const Dashboard1 = () => import('@/views/Dashboard1')
const Dashboard2 = () => import('@/views/Dashboard2')
const Dashboard3 = () => import('@/views/Dashboard3')
const Profile = () => import('@/views/forms/ProfileManagement')
const NDA = () => import('@/views/forms/NDA')
const Report = () => import('@/views/reports/DummyReport')

const Charts = () => import('@/views/charts/Charts')
const Widgets = () => import('@/views/widgets/Widgets')

// Views - Forms
const FormVisitRequest = () => import('@/views/forms/VisitRequest')

const FormMasterCompany = () => import('@/views/forms/CompanyManagement')
const FormMasterSite = () => import('@/views/forms/SiteManagement')
const FormMasterRoom = () => import('@/views/forms/RoomManagement')
const FormMasterUser = () => import('@/views/forms/VisitorManagement')
const FormMasterCard = () => import('@/views/forms/CardManagement')

// Views - Tables
const TableVisitRequest = () => import('@/views/tables/VisitRequest')

const TableMasterCompany = () => import('@/views/tables/TableMasterCompany')
const TableMasterSite = () => import('@/views/tables/TableMasterSite')
const TableMasterRoom = () => import('@/views/tables/TableMasterRoom')
const TableMasterUser = () => import('@/views/tables/TableMasterUser')
const TableMasterCard = () => import('@/views/tables/TableMasterCard')
const TableLogSystem = () => import('@/views/tables/TableLogSystem')


// Views - Reports
const ReportRencanaKunjungan = () => import('@/views/reports/RencanaKunjungan')

// First 9 reports
const TrendPeakHourStatistic = () => import('@/views/reports/TrendPeakHourStatistic')
const TrendDataCenter = () => import('@/views/reports/TrendDataCenter')
const TrendRoom = () => import('@/views/reports/TrendRoom')
const TrendTotalVisitor = () => import('@/views/reports/TrendTotalVisitor')

const ListAllVisitor = () => import('@/views/reports/ListAllVisitor')
const ListNdaStatus = () => import('@/views/reports/ListNdaStatus')
const ListAuthPerson = () => import('@/views/reports/ListAuthPerson')
const ListAllBast = () => import('@/views/reports/ListAllBast')
const ListCardVisitUsage = () => import('@/views/reports/ListCardVisitorUsage')

const PrintBast = () => import('@/views/reports/PrintBast')
const PrintNda = () => import('@/views/reports/PrintNda')

// Views - Pages
const Landing = () => import('@/views/pages/LandingPage')
const VideoPage = () => import('@/views/pages/LandingVideo')
const Faq = () => import('@/views/pages/Faq')
const NoAccess = () => import('@/views/pages/NoAccess')
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')
const Register = () => import('@/views/pages/Register')
const RegisterSuccess = () => import('@/views/pages/Success')
const ResetPassword = () => import('@/views/pages/ResetPassword')

const PageVisitRequest = () => import('@/views/pages/VisitRequest')
const PageVisitorDetail = () => import('@/views/pages/VisitorDetail')
const PageVisitBast = () => import('@/views/pages/VisitBast')
const QRScanner = () => import('@/views/pages/QRScanner')

Vue.use(Router)

const router = new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

//  Roles: 
//  1: Administrator
//  2: Supervisor
//  3: Operator
//  4: PIC Manager
//  5: Receptionist
//  6: Visitor
//  7: Internal
//  8: User
//  9: Super User

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const { authorize } = to.meta;
  const currentUser = localStorage.getItem('user');
  const role = localStorage.getItem('role')

  if (authorize) {
    if (!currentUser) {
      // not logged in so redirect to login page (with the return url)
      // return next({ path: '/auth/login', query: { returnUrl: to.path } });
      return next({ path: '/home' });
    }

    // check if route is restricted by role
    if (authorize.length && !authorize.includes(role)) {
      // role not authorized so redirect to no-access page
      return next({ path: '/no-access' });
    }
  }
  return next();
})

function configRoutes () {
  return [
    {
      path: '/',
      redirect: '/profile',
      name: 'Home',
      component: TheContainer,
      children: [
        // All Users
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard,
          meta: { authorize: ['1', '2', '3', '5'] }
        },
        {
          path: 'profile',
          name: 'Profile',
          component: Profile,
          meta: { authorize: [] }
        },
        {
          path: 'profile/nda',
          name: 'NDA Form',
          component: NDA,
          meta: { authorize: [] }
        },
        {
          path: 'reports/simulation',
          name: 'Dummy Report',
          component: Report,
          meta: { authorize: [] }
        },
        {
          path: 'reports/trend-site',
          name: 'Trend Data Center',
          component: TrendDataCenter,
          meta: { authorize: [] }
        },
        {
          path: 'reports/trend-room',
          name: 'Trend Room',
          component: TrendRoom,
          meta: { authorize: [] }
        },
        {
          path: 'reports/trend-visitor',
          name: 'Trend Total Visitor',
          component: TrendTotalVisitor,
          meta: { authorize: [] }
        },
        {
          path: 'reports/list-visitor',
          name: 'List All Visitor',
          component: ListAllVisitor,
          meta: { authorize: [] }
        },
        {
          path: 'reports/list-nda',
          name: 'List NDA Status',
          component: ListNdaStatus,
          meta: { authorize: [] }
        },
        {
          path: 'reports/list-authorized-person',
          name: 'List Auth Person',
          component: ListAuthPerson,
          meta: { authorize: [] }
        },
        {
          path: 'reports/list-bast',
          name: 'List All BAST',
          component: ListAllBast,
          meta: { authorize: [] }
        },
        {
          path: 'reports/print-bast/:id',
          name: 'Print BAST',
          component: PrintBast,
          meta: { authorize: [] }
        },
        {
          path: 'reports/print-nda/:uid',
          name: 'Print NDA',
          component: PrintNda,
          meta: { authorize: [] }
        },
        {
          path: 'visitors/new-request',
          name: 'Pengajuan Kunjungan',
          component: FormVisitRequest,
          meta: { authorize: [] }
        },
        {
          path: 'approval/scan',
          name: 'Pencarian Pengajuan Kunjungan',
          component: QRScanner,
          meta: { authorize: ['1', '2', '3', '5'] },
        },
        {
          path: 'approval/visit-request',
          name: 'Daftar Pengajuan Kunjungan',
          component: TableVisitRequest,
          meta: { authorize: [] },
        },
        {
          path: 'approval/visit-request/:id',
          name: 'Detail Pengajuan Kunjungan',
          component: PageVisitRequest,
          meta: { authorize: [] },
        },
        {
          path: 'approval/visit-request/:id/:worker_id',
          name: 'Data Pengunjung',
          component: PageVisitorDetail,
          meta: { authorize: [] }
        },
        {
          path: 'approval/bast/:visit_id/:id',
          name: 'Data BAST',
          component: PageVisitBast,
          meta: { authorize: [] }
        },
        {
          path: 'reports/list-card-visit-usage',
          name: 'Data List Card Visit Usage',
          component: ListCardVisitUsage,
          meta: { authorize: [] }
        },
        {
          path: 'reports/trend-peak-hour-statistic',
          name: 'Trend Peak Hour Statistic',
          component: TrendPeakHourStatistic,
          meta: { authorize: [] }
        },
        // Supervisor Role
        {
          path: 'settings/companies',
          name: 'Daftar Perusahaan',
          component: TableMasterCompany,
          meta: { authorize: ['1', '2'] }
        },
        {
          path: 'settings/companies/form/:id',
          name: 'Perubahan Data Perusahaan',
          component: FormMasterCompany
        },
        {
          path: 'settings/users',
          name: 'Daftar Pengguna',
          component: TableMasterUser,
          meta: { authorize: ['1', '2', '3', '4', '5'] }
        },
        {
          path: 'settings/users/approve/:id',
          name: 'Approval Pengguna Baru',
          component: FormMasterUser
        },
        {
          path: 'settings/users/form/:id',
          name: 'Perubahan Data Pengguna',
          component: FormMasterUser
        },
        {
          path: 'settings/sites',
          name: 'Daftar Data Center',
          component: TableMasterSite,
          meta: { authorize: ['1', '2'] }
        },
        {
          path: 'settings/sites/form/:id',
          name: 'Perubahan Data Center',
          component: FormMasterSite
        },
        {
          path: 'settings/rooms',
          name: 'Daftar Ruangan',
          component: TableMasterRoom,
          meta: { authorize: ['1', '2'] }
        },
        {
          path: 'settings/rooms/form/:id',
          name: 'Perubahan Data Ruangan',
          component: FormMasterRoom
        },
        {
          path: 'settings/cards',
          name: 'Daftar Kartu Akses',
          component: TableMasterCard,
          meta: { authorize: ['1', '2'] }
        },
        {
          path: 'settings/cards/form/:id',
          name: 'Perubahan Data Kartu Akses',
          component: FormMasterCard
        },
        {
          path: 'reports/visit-plan',
          name: 'Laporan Rencana Kunjungan',
          component: ReportRencanaKunjungan
        },
        {
          path: 'no-access',
          name: 'No Access',
          component: NoAccess,
          meta: { authorize: [] }
        },
        {
          path: 'settings/log',
          name: 'Log System',
          component: TableLogSystem,
          meta: { authorize: ['1', '2'] }
        },
      ]
    },
    {
      path: '/home',
      name: 'Landing',
      component: Landing
    },
    {
      path: '/video',
      name: 'Video Page',
      component: VideoPage
    },
    {
      path: '/faq',
      name: 'FAQ',
      component: Faq
    },
    {
      path: '/auth',
      redirect: '/auth/login',
      name: 'Auth',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500
        },
        {
          path: 'login',
          name: 'Login',
          component: Login
        },
        {
          path: 'register',
          name: 'Register',
          component: Register
        },
        {
          path: 'register-success',
          name: 'Register Success',
          component: RegisterSuccess
        },
      ]
    },
    {
      path: '/change-password',
      component: ResetPassword,
      props: route => ({ query: route.query.token })
    },
    {
      path: '/ladctbs',
      name: 'Dashboard1',
      component: Dashboard1,
    },
    {
      path: '/ladctkn',
      name: 'Dashboard2',
      component: Dashboard2,
    },
    {
      path: '/ladcjah',
      name: 'Dashboard3',
      component: Dashboard3,
    },
  ]
}

export default router
