import Vue from 'vue';
import Vuex from 'vuex';
import router from '@/router';
import axios from 'axios';
import http from '@/utils/http-common';

Vue.use (Vuex);

export default new Vuex.Store ({
  state: {
    // auth
    user: null,
    roles: null,
    notification: 0,
    lists: [],
    onprogress: [],
    nda: [],

    // menu
    sidebarShow: 'responsive',
    sidebarMinimize: false,
  },

  mutations: {
    // menu
    toggleSidebarDesktop (state) {
      const sidebarOpened = [true, 'responsive'].includes (state.sidebarShow);
      state.sidebarShow = sidebarOpened ? false : 'responsive';
    },
    toggleSidebarMobile (state) {
      const sidebarClosed = [false, 'responsive'].includes (state.sidebarShow);
      state.sidebarShow = sidebarClosed ? true : 'responsive';
    },
    set (state, [variable, value]) {
      state[variable] = value;
    },

    // auth
    setUserData (state, userData) {
      this.state.user = userData;
      this.state.roles = userData.user.roles;
      localStorage.setItem ('user', JSON.stringify (userData));
      localStorage.setItem ('role', userData.user.role);
      localStorage.setItem ('company_id', userData.user.company_id);
      localStorage.setItem ('uid', userData.user.id);
      localStorage.setItem ('token', userData.token);
    },

    updateUserData (state, newData) {
      this.state.user.user.name = newData.name;
      this.state.user.user.image = newData.image;
    },

    getNotified (state, list) {
      let role = +this.state.user.user.role;
      let site_id = +this.state.user.user.site_id;

      if (role <= 2) {
        this.state.notification = +list.length;
        // console.log(list)
        this.state.lists = list;
      } else {
        this.state.notification = +list.filter(item => {
          return item.site_id === site_id;
        }).length;
        this.state.lists = list.filter(item => {
          return item.site_id === site_id;
        });
      }
      
    },

    getProgress (state, list) {
      this.state.onprogress = list;
    },

    postNdaData (state, data) {
      this.state.nda = data;
    },

    clearUserData () {
      this.state.user = null;
      localStorage.removeItem('user');
      localStorage.removeItem('role');
      localStorage.removeItem('company_id');
      localStorage.removeItem('uid');
      localStorage.removeItem('token');
      axios.defaults.headers.common.Authorization = null;
      // location.reload ();
      router.push({ path: '/home' });
    },
  },

  actions: {
    // auth
    /* register ({commit}, credentials) {
      return http.post ('/register', credentials).then (({data}) => {
        commit ('setUserData', data);
      });
    }, */
    login ({commit}, credentials) {
      return http.post ('/login', credentials).then (({data}) => {
        // console.log(data.user.role)
        if (data.status === 'Active') {
          commit ('setUserData', data);
          if (data.code !== '401') {
            router.push({ path: '/profile' });
          }
        } else {
          router.push({ path: '/auth/login' });
          alert (data.message);
        }
      });
    },
    logout({commit}) {
      commit ('clearUserData');
    },
    update({commit}, data) {
      commit ('updateUserData', data);
    },
    notified({commit}) {
      if (this.state.user) {
        return http.get('/visits')
			  .then(function (response) {
				  let data = response.data.data
          let pending = data.filter(function(item) {
    			  return (item.status === 'Pending');  
				  });
          let ongoing = data.filter(function(item) {
    			  return (item.status === 'On-Going');  
				  });

          commit('getNotified', pending);
          commit('getProgress', ongoing);
			  }).catch(function (error) {
				  console.log(error);
			  });
      }
    },
    ndaDetail({commit}, data) {
      commit ('postNdaData', data);
    },
  },

  getters: {
    // auth
    isLogged: state => !!state.user,
    onprogress: state => state.onprogress,
  },
});
