<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="scss">
  // Import Main styles for this application
  @import 'assets/scss/style';
  .animate__animated.animate__flash {
    --animate-duration: 2.5s;
  }
  .card-header {
    background-color: #636f83;
    color: #fff;
  }
</style>
