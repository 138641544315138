import 'core-js/stable'
import Vue from 'vue'
import App from '@/App'
import router from '@/router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from '@/store'
// import axios from 'axios';
import moment from 'moment'
import Skeleton from 'vue-loading-skeleton';
import JsonExcel from 'vue-json-excel';
import VueSignaturePad from 'vue-signature-pad';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import VueToast from 'vue-toast-notification';
import VueSwal from 'vue-swal';
import 'vue-toast-notification/dist/theme-default.css';
//import 'vue-toast-notification/dist/theme-sugar.css
import vuePositionSticky from 'vue-position-sticky'

import http from '@/utils/http-common';

// Registering a single component
// import { CMultiSelect } from '@coreui/vue';

library.add(faCoffee);
Vue.component('font-awesome-icon', FontAwesomeIcon);

// globally
// Vue.component('CMultiSelect', CMultiSelect);
Vue.component('downloadExcel', JsonExcel);

Vue.prototype.moment = moment

Vue.config.performance = true

Vue.use(CoreuiVue);
Vue.use(Skeleton);
Vue.use(VueSignaturePad);
Vue.use(VueToast);
Vue.use(VueSwal);
Vue.use(vuePositionSticky);

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('DD MMM YYYY')
  }
})
Vue.filter('formatTime', function(value) {
  if (value) {
    return moment(String(value)).format('HH:mm')
  }
})

Vue.prototype.$log = console.log.bind(console)

new Vue({
  el: '#app',
  router,
  store,
  created () {
    // to prevent mapstate lost when page refresh
    const userInfo = localStorage.getItem('user')
    if (userInfo) {
      const userData = JSON.parse(userInfo)
      this.$store.commit('setUserData', userData)
    }
    http.interceptors.response.use(
      response => response,
      error => {
        if (error.response.status === 400) {
          this.$store.dispatch('logout')
        }
        if (error.response.status === 401) {
          this.$router.push({ path: '/auth/login' });
        }
        return Promise.reject(error)
      }
    )
  },
  icons,
  template: '<App/>',
  components: {
    App
  }
})
